import React, { Fragment, useState } from "react";
import { graphql } from "gatsby";
import { SEO, Breadcrumbs, IndividualProfileCard, LeadershipProfileCard } from "src/components/commonComponents";
import { CurvedTopArrow, HeroRegular } from "src/components/pageComponents";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import { getPageDataJsonPath } from "src/utils";
const getImageObject = require("src/../helpers/getImageObject")
import LeadershipTable from "src/images/about-us/accruent-leadership/accruent_leadership_hero_conference-table-illustration.png";
const OurLeaderShip = ({ data }) => {
  let leadershipData = [];
  const person = {
    id: "",
    name: "",
    title: "",
    image: "",
    altTag: "",
    sortOrder: 50,
    biography: <Fragment></Fragment>,
  };

  data.allNodeBiography.nodes.forEach((item) => {
    if (item) {
      let personObject = Object.create(person);
      personObject.id = item?.nid;
      personObject.name = item?.title;
      personObject.title = item?.relationships?.fieldLeadershipTitle?.title;
      personObject.nid = item?.nid;
      personObject.sortOrder = item?.relationships?.fieldLeadershipTitle?.fieldSortOrder;
      personObject.biography = item?.body?.value;
      personObject.image = getImageObject(item?.relationships?.fieldHeadshot, {}, "fieldHeadshot");
      leadershipData.push(personObject);
    }
  });

  leadershipData = leadershipData.sort(
    ({ sortOrder: sortOrder1 }, { sortOrder: sortOrder2 }) =>
      sortOrder1 > sortOrder2 ? 1 : -1
  );
  // const { t } = useTranslation();
  const breadcrumbs = [
    { title: "Home", link: "/" },
    { title: "Company", link: "/about-us" },
    { title: "Leadership" },
  ];

  const [selectedCard, setSelectedCard] = useState(null);

  const onSelectCard = (name) => {
    const filteredCard = leadershipData.filter((card) => card.name === name);
    setSelectedCard(filteredCard[0]);
  };
  const OGMarkup = getOGMarkup("Leadership", 'about us');
  const metatags = [
    { name: 'title', content: `Our Leadership | Accruent` },
    { name: 'description', content: `Meet the leaders behind Accurent, a Fortive company that designs facilities and asset management software for companies around the world.` }
  ]
  const pageDataPath = getPageDataJsonPath("/about-us/our-leadership")
  return (
    <Fragment>
      <SEO
        title="Leadership"
        pageUrl="/about-us/our-leadership"
        OGMarkup={OGMarkup}
        meta={metatags}
        alternatives={[
          {
            href: "/about-us/our-leadership",
            hreflang: "en"
          },
          {
            href: "/about-us/our-leadership",
            hreflang: "x-default"
          }
        ]}
        preloads={[pageDataPath]}
        preloadedImages={[{ src: LeadershipTable }]}
      />

      <HeroRegular
        heading="MEET OUR LEADERS"
        description="<p>
          Our experienced leadership team helps drive cohesive strategies,
          build extraordinary teams, and steer Accruent toward sustained success.
        </p>"
        // cta={{
        //   title: "Contact Us",
        //   url: {
        //     path: "/contact-us",
        //   },
        // }}
        imgSrc={LeadershipTable}
        alt="Accruent - About Us - Leadership - Hero - Presentation Illustration"
        hasColumnsReversed
      />

      <div className="tw-relative curved-top before:tw-content-[''] before:tw-h-32 before:tw-bg-top before:tw-bg-no-repeat before:tw-bg-cover before:tw-absolute before:tw-top-[-120px] before:tw0transition-all duration-300 before:tw-w-full before:tw-bg-[url('/img/hero-pattern.svg')]">
        <CurvedTopArrow />
        <div className="tw-pt-6 tw-pb-14 tw-px-4 tw-scroll-smooth tw-scroll-mt-48" id="arrow-down-focus">
          <div className="container">
            <Breadcrumbs crumbs={breadcrumbs} />
            {selectedCard && (
              <IndividualProfileCard
                name={selectedCard.name}
                title={selectedCard.title}
                image={selectedCard.image}
                altTag={selectedCard.altTag}
                bio={selectedCard.biography}
                key={selectedCard.id}
                setSelectedCard={setSelectedCard}
              />
            )}
            <div className="tw-flex-none md:tw-flex tw-mb-3 tw-mx-[-0.75rem] tw-mt-[-0.75rem]">
              <div className="tw-block tw-basis-0 tw-grow tw-shrink tw-p-3">
                <h2 className="tw-text-center tw-break-normal md:tw-text-[40px] tw-text-[32px] tw-leading-10 tw-text-accruent_heading_color tw-font-Poppins tw-font-bold">
                  OUR TEAM
                </h2>
              </div>
            </div>
            <div className="columns is-multiline is-mobile leadership-tiles tw-grid tw-grid-cols-1 md:tw-grid-cols-4 !tw-mb-[20px] ">
              {leadershipData
                .filter(({ name }) => name !== selectedCard?.name)
                .map((card, i) => (
                  <LeadershipProfileCard
                    nid={card.nid}
                    key={`leadership__profile__${card.id}`}
                    name={card.name}
                    title={card.title}
                    bio={card.biography}
                    image={card.image}
                    onSelectCard={onSelectCard}
                    tabIndex={i}
                    aboutUs
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export const query = graphql`
{
  allNodeBiography(
    filter: {relationships: {field_leadership: {elemMatch: {id: {ne: "null"}}}}}
    limit: 1000) {
    nodes {
      nid: drupal_internal__nid
      title
      body {
        value: processed
      }
      relationships {
        fieldLeadership: field_leadership {
          name
        }
        fieldLeadershipTitle: field_leadership_title {
          title
          fieldSortOrder: field_sort_order
        }
        fieldHeadshot: field_headshot {
          fieldImage: field_image {
            alt
          }
          relationships {
            fieldImage: field_image {
              gatsbyImage(width: 600, layout: CONSTRAINED, aspectRatio: 0.91)
            }
          }
        }
      }
    }
  }

}
`;


export default OurLeaderShip;